<!--
 * @Author: DY
 * @Date: 2021-12-09 10:29:52
 * @LastEditTime: 2022-01-21 15:20:01
 * @Description:
 * @Param:
 * @FilePath: \JT_Web_test\src\views\purchaseManage\supplierManage\purchaseCatalog\index.vue
-->
<template>
    <div class="purchaseCatalog">
        <div class="header">
            <h4>砼享平台生产经营物资三级采购目录</h4>
            <div class="search-con">
                <el-input v-model="searchStr" placeholder="请输入内容" suffix-icon="el-icon-search"></el-input>
            </div>
        </div>
        <div class="main clearfix">
            <slider class="el_left" @show-materiel="handleShowMateriel"></slider>
            <div class="materiel-list el_left">
                <tablelist :code="code" :extr="extr"></tablelist>
            </div>
        </div>
    </div>
</template>

<script>
import slider from './slider';
import tablelist from './../../../tableList/index';
export default {
    components: { slider, tablelist },
    props: {},
    data() {
        return {
            searchStr: '',
            code: { TableCode: 'cgml_wl_all' },
            extr: {
                code: { TableCode: 'cgml_wl_all' },
                codeParams: '',
                tabledataParams: {},
            },
            isShow: false,
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 展示右侧物料列表11
        handleShowMateriel(data, type) {
            if (data.category === 1) {
                this.code.ViewCode = 'bpbj';
            } else {
                this.code.ViewCode = 'default';
            }
            if (type === 4) {
                this.code.TableCode = 'cgml_wl';

                this.code.QueryString = 'category=' + data.category + '&catalog_id=' + data.id + '&dic_code=' + data.dic_code + '&code=' + data.code;
                this.extr.tabledataParams = { catalog_id: data.id, dic_code: data.dic_code, code: data.code, category: data.category };
                window.window.$globalHub.$store.commit('setTabledataParams', this.extr.tabledataParams);
            } else {
                this.code.TableCode = 'cgml_wl_all';
                this.extr.tabledataParams = { parent_code: data.code };
                this.code.QueryString = 'parent_code=' + data.code;
            }
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.purchaseCatalog
    .header
        height .5rem
        background #ffffff
        position relative
        h4
            line-height .5rem
            padding-left .2rem
            font-size .16rem
        .search-con
            position absolute
            right .2rem
            top .08rem
            .iconsousuo
                position absolute
                right .1rem
                top .1rem
                color #969EA7
    .main
        height calc(100% - .7rem)
        margin-top .2rem
        .materiel-list
            margin-left .2rem
            height 100%
            background #fff
            width calc(100% - 3.2rem)
</style>